import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import RedirectPage from './components/RedirectPage';
import ConfirmationPremium from './components/ConfirmationPremium';
import ConfirmationResetEmail from './components/ConfirmationResetEmail';

const routing = (
  <Router>
    <div>
      <Route path='/' component={App} />
      <Route path='/return' component={RedirectPage} />
      <Route path='/premium' component={ConfirmationPremium} />
      <Route path='/reset' component={ConfirmationResetEmail} />
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById('root'));
