import styled from 'styled-components';

export const LoginContainer = styled.div``;

export const HeaderContainer = styled.div`
  background-image: url(/static/images/logo-tilia.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const H1 = styled.h1`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 32px;
`;

export const H2 = styled.h2`
  letter-spacing: 2px;
  font-size: 18px;
  text-align: center;
`;
