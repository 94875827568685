import React from 'react';
import { Grid } from 'semantic-ui-react';
import logo from '../static/images/logo-tilia.png';
import logoFooter from '../static/images/tilia-footer.png';

import { H2, HeaderContainer, LoginContainer } from './ui/index.js';
const logoStyle = {
  height: 150,
  marginBottom: '4em',
  marginTop: '2em',
};

const footerStyle = {
  height: 150,
  marginTop: '4em',
};

class ConfirmationResetEmail extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.getTokenPayment.bind(this);
    this.state = {
      status: '',
    };
  }

  getTokenPayment() {
    console.log(this.props);
    console.log('cancel');
    this.setState({ status: 'success' });
    if (window.webkit != undefined) {
      if (window.webkit.messageHandlers.appInterface != undefined) {
        window.webkit.messageHandlers.appInterface.postMessage('refused');
      }
    }
    if (window.appInterface != undefined) {
      window.appInterface.postMessage('refused');
    }
  }

  componentDidMount() {
    console.log('cancel v3');
    this.resetEmailConfirm();
  }

  resetEmailConfirm() {
    console.log('v666666');
    let API_DOMAIN;
    let X_API_KEY;
    let callUrl = window.location.href;
    let pathParam = window.location.search;
    let email = pathParam.substring(7, pathParam.length);
    console.log(email);
    console.log(pathParam);

    switch (callUrl.substring(0, 11)) {
      case 'https://dev':
        API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
        X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
        break;
      case 'https://ppd':
        API_DOMAIN = 'https://ppd-api.lva-ppd.ifsalpha.com';
        X_API_KEY = 'NuHDjReKBQayy6iZsdVUj3HlpOyBsNOe1o3dikDm';
        break;
      case 'https://prd':
        API_DOMAIN = 'https://prd-api.tilia-aidants.fr';
        X_API_KEY = 'ILQ6skss0naN6mzebMOTb8SzSPE295oJ6BZtcVKT';
        break;
      default:
        API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
        X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
    }
    if (this.state.status == '') {
      return fetch(API_DOMAIN + '/profiles/resetPassword', {
        method: 'POST',
        body: JSON.stringify({ email: email.toLowerCase() }),
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': X_API_KEY,
        },
      })
        .then((response) => {
          console.log(response);
          this.setState({ status: 'success' });
          return response.json();
        })
        .then((jsonRes) => {
          console.log(JSON.stringify(jsonRes));
          if (jsonRes) {
            console.log('this is it');
          }
        })
        .catch((err) => {
          alert('une erreur est survenue.');
          return err;
        });
    }
  }

  render() {
    if (this.state.status == '') {
      return null;
    } else
      return (
        <LoginContainer>
          <Grid>
            <Grid.Column
              style={{
                maxWidth: 450,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <HeaderContainer>
                <img style={logoStyle} src={logo} alt='Logo' />
                <H2>{`Votre demande de réinitialisation de mot de passe a bien été prise en compte.`}</H2>
                <br></br>
                <H2>{`Vous allez recevoir votre mot de passe temporaire sur votre adresse mail.`}</H2>
                <a href='https://tilia-aidants.onelink.me/2rW7' id='link'></a>
                <img style={footerStyle} src={logoFooter} alt='Logo' />
              </HeaderContainer>
            </Grid.Column>
          </Grid>
        </LoginContainer>
      );
  }
}
export default ConfirmationResetEmail;
