import queryString from 'query-string';
import React from 'react';
import Loader from 'react-loader-spinner';
import 'whatwg-fetch';

const divStyle = {
    paddingTop: '33%',
    paddingLeft: '46%',
  };
class RedirectPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.getTokenPayment.bind(this);
        this.state = {
          token:'',
          status:''
        };
      }
    
      componentDidMount(){
          this.getTokenPayment();
        }
    getTokenPayment() {
        let API_DOMAIN;
        let X_API_KEY ;
        const values = queryString.parse(this.props.location.search)
        console.log(values);
        console.log(JSON.stringify(values));
        let paylinetoken = values.paylinetoken;
        let profileUUID = values.profileUUID;
        let b2bEmail = values.b2bEmail;
        let campaignName = values.campaignName;
        let type = values.type;
        let callUrl = window.location.href;
        let isUpdated;
        if(values.isUpdated){
          isUpdated = values.isUpdated
        }
        else{
          isUpdated = false;
        }
        console.log("new Version 4: "+isUpdated);
        console.log(callUrl.substring(0, 11));
        console.log(b2bEmail);
        console.log(callUrl);
      
        switch (callUrl.substring(0, 11)) {
          case 'https://dev':
            API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
            X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
            break;
          case 'https://ppd':
            API_DOMAIN = 'https://ppd-api.lva-ppd.ifsalpha.com';
            X_API_KEY = 'NuHDjReKBQayy6iZsdVUj3HlpOyBsNOe1o3dikDm';
            break;
          case 'https://prd':
            API_DOMAIN = 'https://prd-api.tilia-aidants.fr';
            X_API_KEY = 'ILQ6skss0naN6mzebMOTb8SzSPE295oJ6BZtcVKT';
            break;  
          default:
            API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
            X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
        }
      if(this.state.status ==''){
     return fetch(API_DOMAIN+'/payment/get-payment-details', {
          method: 'POST',
          body: JSON.stringify({token:paylinetoken,profileUUID:profileUUID,type:type,b2bEmail,campaignName,isUpdated:isUpdated}),
          headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': X_API_KEY
          }
      }).then(response => {
          console.log(response);
          this.setState({ status: "success" });
          return response.json();})
      .then(jsonRes => {
        if(jsonRes.code=="00000"){
              if (window.webkit != undefined) {
                if (window.webkit.messageHandlers.appInterface != undefined) {
                    window.webkit.messageHandlers.appInterface.postMessage("success")
                }
            }
            if (window.appInterface != undefined) {
                window.appInterface.postMessage("success")
            }
        }
        else{
          if (window.webkit != undefined) {
            if (window.webkit.messageHandlers.appInterface != undefined) {
                window.webkit.messageHandlers.appInterface.postMessage("refused")
            }
        }
        if (window.appInterface != undefined) {
            window.appInterface.postMessage("refused")
        }
        }
    
      })
      .catch(err => {alert('une erreur est survenue.'); return err});
    }
    }        
    
      render() {
        if(this.state.status=='') {return null}
        else 
        return (
            <div style={divStyle}>
     <Loader 
          type="Puff"
          color="#00BFFF"
          height="100"	
          width="100"
       />
       </div>
        );
      }
}
export default RedirectPage
